import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { MQ } from "../../../styles/helpers";
import { gap } from "../../Common/util";

export const Container = styled.div(css`
  display: flex;
  flex-direction: column;
  gap: ${gap.mobile};

  ${MQ("laptop")} {
    flex-direction: row;
    gap: ${gap.laptop};
  }
`);
