import { Slider } from "../../../../../react-app/src/components/Common/Slider/Slider";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { GuideCard } from ".";
import type { GuideContentData } from "@xxl/content-api";

const GUIDE_TEST_ID = "guide-articles-list";

type GuidesProps = {
  guides: GuideContentData[];
};

export const Guides = ({ guides }: GuidesProps) => {
  const guideElements = guides
    .map(({ title, metaDescription, image, publicationDate, url }) => {
      if (
        title === undefined ||
        metaDescription === undefined ||
        image === undefined ||
        publicationDate === undefined ||
        url === undefined
      ) {
        return null;
      }
      return (
        <GuideCard
          key={title}
          title={title}
          metaDescription={metaDescription}
          image={image}
          publicationDate={publicationDate}
          url={url}
        />
      );
    })
    .filter(Boolean) as JSX.Element[];

  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const getPerView = () => {
    if (!isLaptopSize) return 1;
    if (guideElements.length < 3) return guideElements.length;
    return 3;
  };

  return (
    <Slider
      navigation={isLaptopSize ? "ARROWS" : "DOTS"}
      items={guideElements}
      slidesConfig={{
        perView: getPerView(),
        spacing: 12,
      }}
      testId={GUIDE_TEST_ID}
    />
  );
};
