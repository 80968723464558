import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../styles/xxl-theme";
import { MQ, typographyToCss } from "../../styles/helpers";

const { spaces, colors } = xxlTheme;

const commonStyles = {
  lineHeight: 1.2,
  letterSpacing: -0.15,
};

const typography = {
  heading: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    ...commonStyles,
    tablet: {
      fontSize: 34,
    },
  },
  name: {
    fontFamily: "var(--font-family-medium)",
    fontSize: 12,
    ...commonStyles,
    tablet: {
      fontSize: 15,
    },
  },
};

const { heading, name } = typography;

const ICON_SIZES = {
  mobile: "40px",
  tablet: "80px",
};

const ITEM_SIZES = {
  mobile: "80px",
  tablet: "150px",
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h2`
  ${typographyToCss(heading)};
  margin: 0 0 ${spaces.smallRegular};

  ${MQ("tablet")} {
    margin-bottom: ${spaces.large};
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

export const Item = styled.li`
  width: ${ITEM_SIZES.mobile};

  ${MQ("tablet")} {
    width: ${ITEM_SIZES.tablet};
  }
`;

export const IconBackground = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ITEM_SIZES.mobile};
  height: ${ITEM_SIZES.mobile};
  border-radius: 50%;
  background-color: ${colors.xxlLightGreen};
  color: ${colors.xxlWebBlack};
  transition: background-color 0.2s ease;

  ${MQ("tablet")} {
    width: ${ITEM_SIZES.tablet};
    height: ${ITEM_SIZES.tablet};
  }

  & > svg {
    width: ${ICON_SIZES.mobile};
    height: ${ICON_SIZES.mobile};
    transition: transform 0.2s ease;

    ${MQ("tablet")} {
      width: ${ICON_SIZES.tablet};
      height: ${ICON_SIZES.tablet};
    }
  }
`;

export const Link = styled.a`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover,
  &:focus-visible {
    cursor: pointer;

    & svg {
      transform: scale(0.9);
    }

    & ${IconBackground} {
      background-color: ${colors.xxlGreen};
    }
  }
`;

export const CategoryName = styled.span`
  ${typographyToCss(name)};
  text-align: center;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: ${spaces.mini};

  ${MQ("tablet")} {
    margin-top: ${spaces.large};
  }
`;
