import { formatDate } from "../Orders/OrderUtils";

export const getPublicationDateString = (
  locale: string,
  date?: string
): string | undefined => {
  if (date === undefined) {
    return;
  }
  const dateNumber = Date.parse(date);
  return formatDate(dateNumber, locale, false, true);
};

export const getVisibleTeasersNumber = ({
  isSmallMobile,
  isMobileAndTablet,
}: {
  isSmallMobile: boolean;
  isMobileAndTablet: boolean;
}) => {
  if (isSmallMobile) {
    return 2;
  } else if (isMobileAndTablet) {
    return 3;
  }
  return 4;
};
