import type { HomePageLevel1Category, Image } from "@xxl/content-api";
import type { ArrayOfCategoryData } from "@xxl/pim-api";
import type { CategoryData, SubCategoryData } from "./PopularCategories";
import { isNotNullOrUndefined } from "@xxl/common-utils";

export const mapCategoriesToDisplayData = (
  categoriesData: ArrayOfCategoryData,
  level1Categories: HomePageLevel1Category[]
): CategoryData[] => {
  return categoriesData.map((item) => {
    const level1Category = level1Categories
      .filter((c) => isNotNullOrUndefined(c))
      .find((category) => category.ecomCode === item.code);
    const subCategoriesData = isNotNullOrUndefined(item.subCategories)
      ? item.subCategories.slice(0, 3)
      : undefined;
    const subCategories: SubCategoryData[] | undefined = subCategoriesData?.map(
      (subCategory) => ({
        name: subCategory.name,
        url: subCategory.url,
      })
    );
    return {
      image: level1Category?.image as Image,
      name: item.name as string,
      url: item.url as string,
      subCategories: subCategories,
      code: item.code,
    };
  });
};
