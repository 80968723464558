import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../../styles/xxl-theme";
import { MQ, typographyToCss } from "../../../styles/helpers";
import { gap } from "../../Common/util";

const { colors, spaces } = xxlTheme;

const BrandNameTypography = {
  fontFamily: "var(--font-family-bold)",
  fontSize: 16,
  lineHeight: 1.4,
  letterSpacing: -0.15,
  laptop: {
    fontSize: 22,
  },
};

export const BrandWrapper = styled.li`
  height: 80px;
  width: calc((100% - (${gap.mobile} * 2)) / 3);
  max-width: calc((100% - (${gap.mobile} * 2)) / 3);
  flex-grow: 1;
  border: 1px solid ${colors.xxlLightGrey};
  box-sizing: border-box;

  ${MQ("smallTablet")} {
    width: calc((100% - (${gap.mobile} * 2)) / 3);
  }

  ${MQ("tablet")} {
    height: 120px;
    width: calc((100% - (${gap.mobile} * 3)) / 4);
  }

  ${MQ("tabletHorizontal")} {
    width: calc((100% - (${gap.laptop} * 4)) / 5);
  }

  ${MQ("desktop")} {
    width: calc((100% - (${gap.laptop} * 5)) / 6);
  }

  &:hover {
    border-color: ${colors.xxlGreen};
    background-color: ${colors.xxlGreen};
  }
`;

export const Image = styled.img`
  width: 50px;
  height: auto;
  filter: grayscale(1);

  ${MQ("tablet")} {
    width: 80px;
  }
`;

export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-decoration: none;
  background: none;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 ${spaces.smallRegular};
`;

export const BrandName = styled.span`
  ${typographyToCss(BrandNameTypography)};
  text-align: center;
`;
