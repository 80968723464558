import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../styles/xxl-theme";
import { MQ, typographyToCss } from "../../styles/helpers";
import { gap } from "../Common/util";

const { spaces, colors } = xxlTheme;

const commonStyles = {
  lineHeight: 1.2,
  letterSpacing: -0.15,
};

const typography = {
  heading: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    ...commonStyles,
    laptop: {
      fontSize: 34,
    },
  },
  mainCategory: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 15,
    ...commonStyles,
    laptop: {
      fontSize: 24,
    },
  },
  subCategory: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    ...commonStyles,
  },
  showMore: {
    fontFamily: "var(--font-family-medium)",
    fontSize: 15,
    ...commonStyles,
  },
};

const { heading, mainCategory, subCategory, showMore } = typography;

const IMAGE_COMMON_SIZES = {
  mobile: `calc(
    (100dvw - (2 * ${spaces.smallRegular}) - (2 * ${spaces.mini})) / 2.5
  )`,
  tablet: `calc(
    (100dvw - (2 * ${spaces.smallRegular}) - (3 * ${spaces.smallRegular})) / 4
  )`,
  tabletHorizontal: `calc(
    (100dvw - (2 * ${spaces.huge}) - 3 * ${spaces.smallRegular}) / 4
  )`,
  desktop: `calc(
    (100dvw - (2 * ${spaces.huge}) - 5 * ${spaces.smallRegular}) / 6
  )`,
  bigDesktop: "200px",
};

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CategoriesHeading = styled.h2`
  ${typographyToCss(heading)};
  margin: 0 0 ${spaces.smallRegular};
`;

export const CategoriesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  ${MQ("tablet")} {
    flex-wrap: wrap;
    gap: ${gap.mobile};
  }

  ${MQ("bigDesktop")} {
    gap: ${gap.laptop};
  }
`;

export const CategoryItem = styled.li`
  width: ${IMAGE_COMMON_SIZES.mobile};

  ${MQ("tablet")} {
    min-width: auto;
    width: ${IMAGE_COMMON_SIZES.tablet};
  }

  ${MQ("tabletHorizontal")} {
    width: ${IMAGE_COMMON_SIZES.tabletHorizontal};
  }

  ${MQ("desktop")} {
    width: ${IMAGE_COMMON_SIZES.desktop};
    display: flex;
    flex-direction: column;
  }

  ${MQ("bigDesktop")} {
    width: ${IMAGE_COMMON_SIZES.bigDesktop};
  }
`;

export const CategoryImage = styled.img`
  width: 100%;
  height: ${IMAGE_COMMON_SIZES.mobile};

  ${MQ("tablet")} {
    height: ${IMAGE_COMMON_SIZES.tablet};
  }

  ${MQ("tabletHorizontal")} {
    height: ${IMAGE_COMMON_SIZES.tabletHorizontal};
  }

  ${MQ("desktop")} {
    height: ${IMAGE_COMMON_SIZES.desktop};
  }

  ${MQ("bigDesktop")} {
    height: ${IMAGE_COMMON_SIZES.bigDesktop};
  }
`;

export const CategoryLink = styled.a`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CategoryLinkText = styled.span`
  ${typographyToCss(mainCategory)};
  display: block;
  margin: ${spaces.mini} 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.xxlWebBlack};

  ${MQ("laptop")} {
    margin: ${spaces.smallRegular} 0 ${spaces.mini};
  }
`;

export const SubCategoriesList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${spaces.hair};
`;

export const SubCategoryListItem = styled.li`
  width: 100%;
`;

export const SubCategoryListLink = styled.a`
  ${typographyToCss(subCategory)};
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  color: ${colors.xxlWebGrey};

  &:hover,
  &:focus-visible {
    font-family: var(--font-family-medium);
    color: ${colors.xxlWebBlack};
  }
`;

export const ShowMoreButton = styled.button`
  ${typographyToCss(showMore)};
  border: 1px solid ${colors.xxlMediumGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: 0.2s ease;
  transition-property: background, border-color;

  ${MQ("tablet")} {
    width: ${IMAGE_COMMON_SIZES.tablet};
    height: ${IMAGE_COMMON_SIZES.tablet};
  }

  ${MQ("tabletHorizontal")} {
    width: ${IMAGE_COMMON_SIZES.tabletHorizontal};
    height: ${IMAGE_COMMON_SIZES.tabletHorizontal};
  }

  ${MQ("desktop")} {
    width: ${IMAGE_COMMON_SIZES.desktop};
    height: ${IMAGE_COMMON_SIZES.desktop};
  }

  ${MQ("bigDesktop")} {
    width: ${IMAGE_COMMON_SIZES.bigDesktop};
    height: ${IMAGE_COMMON_SIZES.bigDesktop};
  }

  &:hover,
  &:focus-visible {
    background: ${colors.xxlGreen};
    border-color: ${colors.xxlGreen};
    cursor: pointer;
  }
`;
