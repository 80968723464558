import type {
  PurchaseLineSummary,
  AccountPurchase,
  AccountPurchaseSummary,
} from "./Api/OrdersAPI";

const CLICK_AND_COLLECT = "777";
const PICKUP_AT_STORE = "666";

export const CASH_DESK_PICKUP = "manual";
export const SELF_SERVICE_PICKUP = "cleveron";

export const getOrderIdFromSK = (SK: string): string =>
  SK.split("#").pop() ?? SK;

export const truncateOrderId = (ID: string): string =>
  ID.length > 11 ? `...${ID.substring(ID.length - 9, ID.length)}` : ID;

export const formatDate = (
  orderDate: number,
  lang = "en",
  short = false,
  onlyDate?: boolean
): string => {
  const date = new Date(orderDate);
  let dateString;
  if (short) {
    dateString = date.toISOString().substring(0, 10);
  } else {
    const formatter = new Intl.DateTimeFormat(lang, { month: "short" });
    const day = date.getDate();
    const month = formatter.format(date);
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const minutesString = minutes < 10 ? `0${minutes}` : minutes;
    if (onlyDate === true) {
      dateString = `${String(day)} ${String(month)} ${String(year)}`;
    } else {
      dateString = `${String(day)} ${String(month)} ${String(year)} ${String(
        hours
      )}:${String(minutesString)}`;
    }
  }

  return dateString;
};

export const getQuantityReturned = (item: PurchaseLineSummary): number =>
  item.returns
    ?.filter(({ status }) => status === "ACCEPTED")
    ?.reduce((sum, next) => sum + (next.quantity ?? 0), 0) ?? 0;

export const isEcomOrder = ({
  salesChannel,
}: AccountPurchase | AccountPurchaseSummary): boolean =>
  salesChannel === "ECOM";
export const isAppOrder = ({
  salesChannel,
}: AccountPurchase | AccountPurchaseSummary): boolean => salesChannel === "APP";
export const isKioskOrder = ({
  salesChannel,
}: AccountPurchase | AccountPurchaseSummary): boolean =>
  salesChannel === "KIOSK";

export const isPickupOrder = (purchase: AccountPurchase): boolean =>
  Boolean(
    purchase.shipping.method?.id === PICKUP_AT_STORE ||
      purchase.shipping.method?.id === CLICK_AND_COLLECT
  );
