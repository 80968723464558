import styled from "@emotion/styled/macro";
import { MQ } from "../../../styles/helpers";
import { gap } from "../../Common/util";

export const BrandListWrapper = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${gap.mobile};
  box-sizing: border-box;
  min-height: 80px;

  ${MQ("tablet")} {
    min-height: 120px;
  }

  ${MQ("laptop")} {
    gap: ${gap.laptop};
  }
`;
