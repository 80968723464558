import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { MQ } from "../../../styles/helpers";
import { getBannerHeights } from "../Shared/BannerContent/BannerContent.helper";
import { gap } from "../../Common/util";

const { halfGrid, quarterHorizontal, quarterVertical } = getBannerHeights();
const scalingFactor = 0.2;
export const Wrapper = styled.div(css`
  display: grid;
  gap: ${gap.mobile};
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: ${halfGrid.mobile} ${quarterHorizontal.mobile} ${quarterVertical.mobile};
  grid-template-areas:
    "first first"
    "third fourth"
    "second second";

  ${MQ("tablet")} {
    height: calc(400px - (1332px - 100vw) * ${scalingFactor});
    max-height: 400px;
    gap: ${gap.laptop};
    grid-template-columns: calc(50% - ${gap.laptop}/2) 18% auto;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: "first second third" "first second fourth";
   `);
